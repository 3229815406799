import { mapToList } from "./utils";

export const EnumGoodsType = {
  // 3C商品
  C3: 1,
  // 电车商品
  ELECTRIC: 2,
};

export const GoodsTypeToTextMap = new Map([
  [EnumGoodsType.C3, "3C商品"],
  [EnumGoodsType.ELECTRIC, "电车商品"],
]);

export const GoodsTypeList = mapToList(GoodsTypeToTextMap);

export const EnumGoodsGroupCode = {
  MODEL: "model",
  COLOR: "color",
  MEMORY: "memory",
};

export const GoodsGroupCodeToTextMap = new Map([
  [EnumGoodsGroupCode.MODEL, "型号"],
  [EnumGoodsGroupCode.COLOR, "颜色"],
  [EnumGoodsGroupCode.MEMORY, "内存"],
]);

export const GoodsGroupCodeList = mapToList(GoodsGroupCodeToTextMap);

export const GoodsTypeToGoodsGroupCodeMap = new Map([
  [
    EnumGoodsType.C3,
    [
      EnumGoodsGroupCode.MODEL,
      EnumGoodsGroupCode.COLOR,
      EnumGoodsGroupCode.MEMORY,
    ],
  ],
  [
    EnumGoodsType.ELECTRIC,
    [EnumGoodsGroupCode.MODEL, EnumGoodsGroupCode.COLOR],
  ],
]);
